import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { css } from 'emotion'
// import actions from '../store/aspects/actions'
import { getChildAspectsFor } from '../store/reducers'
import { Card, Button, IconEdit, IconZoom } from './common'

const aspectStyle = css`
  display: grid;
  grid-template-columns: [aspect-start] minmax(min-content, 1fr) [aspect-end button-start] minmax(
      2rem,
      min-content
    ) [button-end];

  &:hover > .hoverShow {
    visibility: visible;
  }

  .name {
    padding: 1rem;
    grid-column: aspect;
    font-weight: bold;
    font-size: 110%;
    margin-bottom: 0.2rem;
  }
`

const childAspect = css`
  grid-column: 1 / -1;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.2rem;
`

const buttonGroupStyle = css`
  grid-column: button;
  align-self: start;
  background-color: #fff;
  visibility: hidden;
  padding: 0.5rem;
  margin: 0.5rem;

  .icon {
    height: 2rem;
    width: 2rem;
    fill: darkgray;
  }

  &:hover > *:hover {
    background-color: #eee;
  }

`

const editButtonStyle = css`
`

const linkStyle = css`
  border: none;
  outline: none;
  padding: 0.5rem;
  display: block;
`

class AspectDetailView extends React.Component {
  renderChildAspects() {
    const { childAspects } = this.props
    return childAspects.map(child => (
      <Card className={childAspect} noshadow key={child.id}>
        {child.name}
      </Card>
    ))
  }

  render() {
    const { aspect, startEdit } = this.props
    return (
      <Card className={aspectStyle}>
        <div className="name">{aspect.name}</div>
        <div className={`${buttonGroupStyle} hoverShow`}>
          <Link
            to={`/aspect/${aspect.id}`}
            className={linkStyle}
          >
            <IconZoom />
          </Link>
          <Button className={editButtonStyle} onClick={startEdit}>
            <IconEdit />
          </Button>
        </div>
        {this.renderChildAspects()}
      </Card>
    )
  }
}

function mapStateToProps(state, props) {
  const { id } = props.aspect
  const childAspects = getChildAspectsFor(state, id)
  return { childAspects }
}

export default connect(mapStateToProps)(AspectDetailView)
