import React from 'react'
// import './icons.svg'

const Icon = props => {
  const { iconTitle = '', width = 24, height = 24, children } = props
  return (
    <svg
      className={`icon icon-${iconTitle}`}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby={`icon-${iconTitle}`}
    >
      <title id={`icon-${iconTitle}`}>{iconTitle}</title>
      {children}
    </svg>
  )
}

export default Icon
