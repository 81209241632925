import types from '../types'
import api from '../../api/firebase'

export function fetchAspects(result) {
  result = result || api.fetchAspects()
  return {
    type: types.FETCH_ASPECTS,
    payload: result,
  }
}

export const watchAspects = () => dispatch => {
  const all = docs => dispatch(fetchAspects(docs))
  const add = doc => dispatch({ type: types.ADD_ASPECT, payload: doc })
  const remove = id => dispatch({ type: types.REMOVE_ASPECT, payload: id })
  api.watchAspects({
    all,
    add,
    remove,
  })
}

export const addAspect = aspect => async dispatch => {
  try {
    return await api.addAspect({ ...aspect })
  } catch (err) {
    console.error(err)
  }
}

export const deleteAspect = id => async dispatch => {
  try {
    return await api.deleteAspect(id)
  } catch (err) {
    console.error(err)
  }
}

export const saveAspect = aspect => async dispatch => {
  try {
    const { id, ...save } = aspect
    return await api.saveAspect(id, save)
  } catch (err) {
    console.error(err)
  }
}

export default {
  fetchAspects,
  addAspect,
  deleteAspect,
  watchAspects,
  saveAspect,
}
