// import React from 'react'
import styled from 'react-emotion'

const Grid = styled('div')`
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-auto-rows: minmax(5rem, max-content);
`
export default Grid
