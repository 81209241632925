import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import api from './api/firebase'
import configureStore from './configureStore'
import Root from './Root'
import App from './App'
// import registerServiceWorker from './registerServiceWorker'
import config from './config'

api.initialize(config.firebase)

const store = configureStore()

ReactDOM.render(
  <Root store={store}>
    <App />
  </Root>,
  document.getElementById('root')
)
// registerServiceWorker()
