// import React from 'react'
import styled from 'react-emotion'

const boxShadow = props => {
  const { noshadow } = props
  if (noshadow) {
    return 'none'
  }
  return `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`
}

const Card = styled('div')`
  background-color: #fff;
  border-radius: 2px;
  box-shadow: ${boxShadow};
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25), 0 3px 6px rgba(0, 0, 0, 0.22);
  }
`

export default Card
