import React from 'react'
import AspectDetailView from './AspectDetailView'
import AspectEdit from './AspectEdit'

class Aspect extends React.Component {
  state = { editing: false }

  startEdit = () => {
    this.setState({ editing: true })
  }

  endEdit = () => {
    this.setState({ editing: false })
  }

  render() {
    if (this.state.editing) {
      return (
        <AspectEdit
          {...this.props}
          startEdit={this.startEdit}
          endEdit={this.endEdit}
        />
      )
    } else {
      return (
        <AspectDetailView
          {...this.props}
          startEdit={this.startEdit}
          endEdit={this.endEdit}
        />
      )
    }
  }
}

export default Aspect
