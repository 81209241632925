import React from 'react'
import { css } from 'emotion'

class Header extends React.Component {
  render() {
    return (
      <div className={headerStyle}>
        <div className="title">
          <h1 className="title">Fate Fractal</h1>
        </div>
        <div className="right">
        </div>
      </div>
    )
  }
}

const headerStyle = css`
  background-color: lightgrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;

  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`

export default Header
