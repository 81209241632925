import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { css } from 'emotion'
import { getAspectById } from '../store/reducers'

import { IconZoomOut } from './common'

class BreadCrumbs extends React.Component {

  render() {
    const { aspect, parentAspect } = this.props

    if(!aspect) {
      return null
    }

    return (
      <div className={breadcrumbsStyle}>
        <h2>
          {parentAspect
            ? <Link to={`/aspect/${parentAspect.id}`}>
                <IconZoomOut />
              </Link>
            : <Link to="/">
                <IconZoomOut />
              </Link>
          }
          {aspect.name}
        </h2>
      </div>
    )
  }
}

const breadcrumbsStyle = css`
  width: 100%;
  text-align: center;
  a {
    margin-right: 1rem;
  }
`

function mapStateToProps(state, ownProps) {
  const { currentId } = ownProps
  const aspect = getAspectById(state, currentId)
  const parentAspect = aspect && getAspectById(state, aspect.parentId)
  const grandParentAspect = parentAspect && getAspectById(state, parentAspect.parentId)

  return {
    aspect,
    parentAspect,
    grandParentAspect,
  }
}

export default connect(mapStateToProps)(BreadCrumbs)
