import React from 'react'
import { connect } from 'react-redux'
import { css } from 'emotion'
import { getChildAspectsFor, getAspectById } from '../store/reducers'
import { deleteAspect, watchAspects } from '../store/actions'
import { Grid } from './common'
import Aspect from './Aspect'
import BreadCrumbs from './BreadCrumbs'
import AspectAdd from './AspectAdd'

class AspectRootView extends React.Component {
  componentWillMount() {
    this.props.watchAspects()
  }

  onDeleteAspect = id => {
    this.props.deleteAspect(id)
  }

  renderAspects = () => {
    return this.props.aspects.map(aspect => {
      return (
        <Aspect
          key={aspect.id}
          aspect={aspect}
          onDelete={this.onDeleteAspect}
        />
      )
    })
  }

  renderPageHeader() {
    const { rootAspect } = this.props
    return (
      rootAspect
      ? <h2>{rootAspect.name}</h2>
      : null
    )
  }

  render() {
    const { rootAspect } = this.props
    return (
      <div className="page">
        <div className={aspectHeader}>
          <AspectAdd rootAspect={rootAspect}/>
        </div>
        {rootAspect && <BreadCrumbs currentId={rootAspect.id} />}
        <Grid className={gridStyle}>{this.renderAspects()}</Grid>
      </div>
    )
  }
}

const aspectHeader = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
`

const gridStyle = css`
margin-top: .5rem;
`

function mapStateToProps(state, props) {
  const parentId = props.match.params.id
  const rootAspect = getAspectById(state, parentId)
  const aspects = getChildAspectsFor(state, parentId)
  return {
    aspects,
    rootAspectId: parentId,
    rootAspect,
  }
}

const mapDispatchToProps = {
  watchAspects,
  deleteAspect,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AspectRootView)
