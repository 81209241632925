import React from 'react'
import Icon from './Icon'

const IconEdit = props => {
  const { iconTitle = 'save' } = props
  return (
    <Icon {...props} iconTitle={iconTitle}>
      <path d="M21.7 7.3l-5-5c-0.2-0.2-0.4-0.3-0.7-0.3h-11c-1.7 0-3 1.3-3 3v14c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-11c0-0.3-0.1-0.5-0.3-0.7zM16 20h-8v-6h8v6zM20 19c0 0.6-0.4 1-1 1h-1v-7c0-0.6-0.4-1-1-1h-10c-0.6 0-1 0.4-1 1v7h-1c-0.6 0-1-0.4-1-1v-14c0-0.6 0.4-1 1-1h1v4c0 0.6 0.4 1 1 1h8c0.6 0 1-0.4 1-1s-0.4-1-1-1h-7v-3h7.6l4.4 4.4v10.6z" />
    </Icon>
  )
}

export default IconEdit
