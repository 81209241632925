import { combineReducers } from 'redux'
import aspects, * as fromAspects from './aspects'

export default combineReducers({
  aspects,
  // selectedRootAspect: rootAspect.reducer,
})

export const getAllAspects = state => fromAspects.getAllAspects(state.aspects)

export const getAspectById = (state, id) =>
  fromAspects.getAspectById(state.aspects, id)

export const getChildAspectsFor = (state, id) =>
  fromAspects.getChildAspectsFor(state.aspects, id)
