import { combineReducers } from 'redux'
import types from '../types'

const aspect = (state, action) => {
  switch (action.type) {
    case types.ADD_ASPECT:
      return { ...action.payload }
    default:
      return state
  }
}

// const aspects = (state = {}, action) => {
//   switch(action.type) {
//     case types.FETCH_ASPECTS:
//       return _.mapKeys(action.payload, 'id')
//     case types.ADD_ASPECT:
//       const a = aspect(undefined, action)
//       return { ...state, [a.id]: a }
//     case types.REMOVE_ASPECT:
//       const { [action.payload]: omit, ...keep } = state
//       return keep
//     default:
//       return state
//   }
// }

const byId = (state = {}, action) => {
  switch (action.type) {
    case types.ADD_ASPECT:
      const { id } = action.payload
      return {
        ...state,
        [id]: aspect(state[id], action),
      }
    case types.REMOVE_ASPECT:
      const { [action.payload]: omit, ...keep } = state
      return keep
    default:
      return state
  }
}

const allIds = (state = [], action) => {
  switch (action.type) {
    case types.ADD_ASPECT:
      const { id } = action.payload
      if (state.includes(id)) {
        return state
      }
      return [...state, action.payload.id]
    case types.REMOVE_ASPECT:
      return state.filter(id => id !== action.payload)
    default:
      return state
  }
}

export default combineReducers({
  byId,
  allIds,
})

export const getAllAspects = state => state.allIds.map(id => state.byId[id])

export const getAspectById = (state, id) => state.byId[id]

export const getChildAspectsFor = (state, id) =>
  getAllAspects(state).filter(aspect => aspect.parentId === id)
