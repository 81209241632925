import React from 'react'
import { /*cx,*/ css } from 'emotion'
import Card from './Card'

const flipdyStyle = css`
  position: relative;
  perspective: 1500px;
  -moz-perspective: 1500px;
  /* height: 100%;
  width: 100%; */
`

const sideStyle = css`
  /* height: 100%; */
  width: 100%;
  padding: 0;
  transition: all 1.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
`

const frontStyle = css`
  background-color: red;
  transform: rotateY(0);
`

const backStyle = css`
  background-color: green;
  transform: rotateY(180deg);
`

const flipFront = css`
  transform: rotateY(-180deg);
`

const flipBack = css`
  transform: rotateY(0);
`

class FlipdyCard extends React.Component {
  state = { showFront: true }

  doFlip = () => {
    this.setState((state, props) => ({ showFront: !state.showFront }))
  }

  render() {
    const { front, back } = this.props
    const { showFront } = this.state
    return (
      <Card className={flipdyStyle} onClick={this.doFlip}>
        {/* <div
          className={cx(
            sideStyle,
            frontStyle,
            {[flipFront]: !showFront},
        )}>
          {front()}
        </div> */}
        {/* <div
          className={cx(
            sideStyle,
            backStyle,
            {[flipBack]: showFront}
          )}>{back()}</div> */}
        <div
          className={`${sideStyle} ${frontStyle} ${showFront ? '' : flipFront}`}
        >
          {front()}
        </div>
        <div
          className={`${sideStyle} ${backStyle} ${showFront ? '' : flipBack}`}
        >
          {back()}
        </div>
      </Card>
    )
  }
}

export default FlipdyCard
