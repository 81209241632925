import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import { injectGlobal } from 'emotion'
import Layout from './components/Layout'
import Header from './components/Header'
import AspectRootView from './components/AspectRootView'

class App extends Component {
  render() {
    return (
      <Layout className="App">
        <Header />
        <Route path="/" exact component={AspectRootView} />
        <Route path="/aspect/:id" component={AspectRootView} />
      </Layout>
    )
  }
}

injectGlobal`
*,
*::after,
*::before, {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  box-sizing: border-box;
  height: 100%;
}

#root,
.App {
  height: 100%;
}
`

export default App
