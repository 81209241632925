import React from 'react'
import Icon from './Icon'

const IconZoom = props => {
  const { iconTitle = 'zoom' } = props
  return (
    <Icon {...props} iconTitle={iconTitle}>
      <path d="M21.7 20.3l-3.7-3.7c1.2-1.5 2-3.5 2-5.6 0-5-4-9-9-9s-9 4-9 9c0 5 4 9 9 9 2.1 0 4.1-0.7 5.6-2l3.7 3.7c0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3c0.4-0.4 0.4-1 0-1.4zM4 11c0-3.9 3.1-7 7-7s7 3.1 7 7c0 1.9-0.8 3.7-2 4.9 0 0 0 0 0 0s0 0 0 0c-1.3 1.3-3 2-4.9 2-4 0.1-7.1-3-7.1-6.9z"></path>
      <path d="M14 10h-2v-2c0-0.6-0.4-1-1-1s-1 0.4-1 1v2h-2c-0.6 0-1 0.4-1 1s0.4 1 1 1h2v2c0 0.6 0.4 1 1 1s1-0.4 1-1v-2h2c0.6 0 1-0.4 1-1s-0.4-1-1-1z"></path>
    </Icon>
  )
}

export default IconZoom
