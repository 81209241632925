import { createStore, applyMiddleware, compose } from 'redux'
import reducers from './store/reducers'
import reduxPromise from 'redux-promise'
import thunk from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = (initialState = {}) => {
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(reduxPromise, thunk))
  )

  return store
}

export default configureStore
