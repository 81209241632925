import firebase from 'firebase/app'
import 'firebase/firestore'

let db

const settings = {
  timestampsInSnapshots: true,
}

const initialize = config => {
  firebase.initializeApp(config)
  db = firebase.firestore()
  db.settings(settings)
}

const addToCollection = collection => document => {
  return db
    .collection(collection)
    .add(document)
    .then(docRef => docRef.get())
    .then(doc => doc.data())
}

const addAspect = addToCollection('aspects')

const fetchCollection = collection => () => {
  return db
    .collection(collection)
    .get()
    .then(querySnapshot => {
      return querySnapshot.docs.map(doc => {
        return { ...doc.data(), id: doc.id }
      })
    })
}

const fetchAspects = fetchCollection('aspects')

const deleteFromCollection = collection => id => {
  return db
    .collection(collection)
    .doc(id)
    .delete()
}

const deleteAspect = deleteFromCollection('aspects')

const watchCollection = collection => callbacks => {
  db.collection(collection).onSnapshot(querySnapshot => {
    querySnapshot.docChanges().forEach(change => {
      // change.type can be "added", "modified", "removed"
      switch (change.type) {
        case 'added':
        case 'modified':
          const doc = { ...change.doc.data(), id: change.doc.id }
          return callbacks.add(doc)
        case 'removed':
          return callbacks.remove(change.doc.id)
        default:
          return null
      }
    })
    // const docs = querySnapshot.docs.map(doc => {
    //   return { ...doc.data(), id: doc.id }
    // })
    // callbacks.all(docs)
  })
}
const watchAspects = watchCollection('aspects')

const saveToCollection = collection => (id, doc) => {
  return db
    .collection(collection)
    .doc(id)
    .set(doc, { merge: true })
}

const saveAspect = saveToCollection('aspects')

export default {
  initialize,
  addAspect,
  fetchAspects,
  deleteAspect,
  watchAspects,
  saveAspect,
}
