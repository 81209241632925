// import React from 'react'
import styled from 'react-emotion'

const Layout = styled('div')`
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-size: 1.6rem;
  display: grid;
  grid-template-rows: 5rem 1fr;
  grid-template-areas:
    'header'
    'main';

  .page {
    grid-area: main;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
  }
`

export default Layout
