import React from 'react'
import Icon from './Icon'

const IconEdit = props => {
  const { iconTitle = 'cancel' } = props
  return (
    <Icon {...props} iconTitle={iconTitle}>
      <path d="M19.8 19.8c2-2 3.2-4.7 3.2-7.8 0-6.1-4.9-11-11-11-3 0-5.8 1.2-7.8 3.2 0 0 0 0 0 0s0 0 0 0c-2 2-3.2 4.8-3.2 7.8 0 6.1 4.9 11 11 11 3 0 5.8-1.2 7.8-3.2 0 0 0 0 0 0s0 0 0 0zM21 12c0 2.1-0.7 4.1-2 5.6l-12.6-12.6c1.5-1.3 3.5-2 5.6-2 5 0 9 4 9 9zM3 12c0-2.1 0.7-4.1 2-5.6l12.6 12.6c-1.5 1.2-3.5 2-5.6 2-5 0-9-4-9-9z" />
    </Icon>
  )
}

export default IconEdit
