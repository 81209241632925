import React from 'react'
import { connect } from 'react-redux'
import { css } from 'emotion'
import * as actions from '../store/actions'

const aspectAddStyle = css`
  width: 100%;
  padding: 1rem 2rem;

  form {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .title {
    font-weight: bold;
    margin-right: 10px;
  }

  input {
    flex: 1 0 10rem;
  }
`

class AspectAdd extends React.Component {
  state = {
    name: ''
  }

  handleSubmit = event => {
    event.preventDefault()
    const { rootAspect } = this.props
    const newAspect = {
      ...this.state
    }
    if(rootAspect) {
      newAspect.parentId = rootAspect.id
    }
    console.log('adding aspect', newAspect)
    this.props.addAspect(newAspect)
    this.setState({ name: '' })
  }

  handleChange = event => {
    this.setState({ name: event.target.value })
  }

  render() {
    return (
      <div className={aspectAddStyle}>
        <form onSubmit={this.handleSubmit}>
          <div className="title">Add An Aspect</div>
          <input
            type="text"
            onChange={this.handleChange}
            value={this.state.name}
          />
          <button>Add</button>
        </form>
      </div>
    )
  }
}

export default connect(
  null,
  actions
)(AspectAdd)
