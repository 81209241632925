import React from 'react'
import { connect } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import * as actions from '../store/actions'
import { css } from 'emotion'
import { Card, Button, IconCancel, IconSave, IconDelete } from './common'
const aspectEditStyle = css``
const formStyle = css`
  display: grid;
  grid-template-columns: [aspect-start left-start] minmax(2rem, min-content) [left-end] minmax(
      min-content,
      1fr
    ) [right-start] minmax(2rem, min-content) [aspect-end right-end];
  input[type='text'] {
    width: 95%;
    font-family: inherit;
    font-size: 1.2rem;
    margin: 1rem auto;
    padding: 0.2rem;
    grid-column: aspect;
  }

  .icon {
    width: 2rem;
    height: 2rem;
    fill: darkgray;
  }

  .icon-delete {
    fill: red;
  }

  .icon-save {
    fill: lightgreen;
  }

  button:hover {
    background-color: lightgray;
  }
`
const leftButtons = css`
  grid-column: left;
  display: flex;
  margin-left: 0.5rem;
`

const rightButtons = css`
  grid-column: right;
  margin-right: 0.5rem;
`

class AspectEdit extends React.Component {
  onCancel = () => {
    this.props.endEdit()
  }

  render() {
    const { aspect, endEdit, saveAspect, onDelete } = this.props
    return (
      <Card className={aspectEditStyle}>
        <Formik
          initialValues={aspect}
          onSubmit={async (values, actions) => {
            try {
              await saveAspect(values)
            } catch (e) {
              console.error('Error saving', e)
              // actions.setErrors(errorsObject)
            } finally {
              actions.setSubmitting(false)
              endEdit()
            }
          }}
          onReset={() => this.onCancel()}
          render={({ errors, touched, isSubmitting, handleReset }) => (
            <Form className={formStyle}>
              <Field type="text" name="name" />
              <div className={leftButtons}>
                <Button
                  type="button"
                  className="cancelButton"
                  onClick={handleReset}
                >
                  <IconCancel />
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  <IconSave />
                </Button>
              </div>
              <div className={rightButtons}>
                {!aspect.children &&
                  <Button type="button" onClick={() => onDelete(aspect.id)}>
                    <IconDelete />
                  </Button>

                }
              </div>
            </Form>
          )}
        />
      </Card>
    )
  }
}

export default connect(
  null,
  actions
)(AspectEdit)
