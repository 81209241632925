import React from 'react'
import Icon from './Icon'

const IconEdit = props => {
  const { iconTitle = 'edit' } = props
  return (
    <Icon {...props} iconTitle={iconTitle}>
      <path d="M20 13.7c-0.6 0-1 0.4-1 1v5.3c0 0.6-0.4 1-1 1h-14c-0.6 0-1-0.4-1-1v-14c0-0.6 0.4-1 1-1h5.3c0.6 0 1-0.4 1-1s-0.4-1-1-1h-5.3c-1.7 0-3 1.3-3 3v14c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3v-5.3c0-0.6-0.4-1-1-1z" />
      <path d="M22.7 5.3l-4-4c-0.4-0.4-1-0.4-1.4 0l-10 10c-0.2 0.2-0.3 0.4-0.3 0.7v4c0 0.6 0.4 1 1 1h4c0.3 0 0.5-0.1 0.7-0.3l10-10c0.4-0.4 0.4-1 0-1.4zM11.6 15h-2.6v-2.6l9-9 2.6 2.6-9 9z" />
    </Icon>
  )
}

export default IconEdit
